import React from "react";
import { useState, useEffect } from "react";
import Axios from "axios";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../Payment/CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";

import "./Form.css";

import SkwerLogo from "../../assets/images/skwer_b.png";

function Form() {
  /* VARIABLES DECLARATION */

  const fields = {
    or_no: 0,
    salutation: "Mr.",
    f_name: "",
    l_name: "",
    total: 0,
    email: "",
    contact_no: "",
    package: "",
    package_price: 0,
    add_serv: [],
    add_serv_price: 0,
    bank_serv: [],
    bank_serv_price: 0,
  };

  const [formData, setFormData] = useState(fields);

  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

  const [ORValidation, setORValidation] = useState("");

  const id = formData.or_no;

  const [total, setTotal] = useState(0);

  var package_amt = formData.package_price;
  var serv_amt = formData.add_serv_price;
  var bank_amt = formData.bank_serv_price;

  /*  https://skwerstripeapi.skwergroup.com */
  const monitorPayment = () => {
    Axios.post("https://skwerstripeapi.skwergroup.com/monitorPayment", {
      or_no: formData.or_no,
      remarks: "Initialized",
      updated_date: date + " " + time,
    }).then(() => {
      console.log("monitoring...");
    });
  };

  /* DATABASE AXIOS */
  const getDetails = (e) => {
    // Fetch data from the backend API using Axios
    Axios.get(`https://skwerstripeapi.skwergroup.com/getDetails/data/${id}`)
      .then((response) => {
        if (response.data && response.data.length > 0) {
          //save payment monitoring
          monitorPayment();

          // console.log(response.data[0]);
          setTotal(
            response.data[0].package_price +
              response.data[0].add_serv_price +
              response.data[0].bank_serv_price
          );
          setFormData({
            ...formData,
            or_no: id,
            salutation: response.data[0].salutation,
            f_name: response.data[0].f_name,
            l_name: response.data[0].l_name,
            amount:
              response.data[0].package_price +
              response.data[0].add_serv_price +
              response.data[0].bank_serv_price,
            email: response.data[0].email,
            contact_no: response.data[0].contact_no,
            package: response.data[0].package,
            package_price: response.data[0].package_price,
            add_serv: response.data[0].add_serv,
            add_serv_price: response.data[0].add_serv_price,
            bank_serv: response.data[0].bank,
            bank_serv_price: response.data[0].bank_serv_price,
          });

          setORValidation("");

          setBtnDisplaySummary("display__block");

          handleCheckout(
            response.data[0].f_name,
            response.data[0].l_name,
            id,
            response.data[0].package_price +
              response.data[0].add_serv_price +
              response.data[0].bank_serv_price
          );
        } else {
          // Handle the case when response.data[0] is empty or undefined
          setORValidation("Invalid input. Please check ORDER NUMBER properly.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setORValidation("Invalid input. Please check ORDER NUMBER properly.");
      });
  };

  const [clientSecret, setClientSecret] = useState("");
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    /* http://localhost:3001 https://skwerstripeapi.skwergroup.com */
    fetch("https://skwerstripeapi.skwergroup.com/config").then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  /* CHECKBOX/MAKEPAYMENT APPEAR */
  const [btnStripe, setBtnStripe] = useState("deactivated");

  // const activePay = () => {
  //   if (btnStripe === "deactivated") {
  //     setBtnStripe("activated");
  //   } else {
  //     setBtnStripe("deactivated");
  //   }
  // }; /* END */

  /* DISPLAY DETAILS */
  const [btnDisplaySummary, setBtnDisplaySummary] = useState("display__none");

  const handleCheckout = (f_name, l_name, or_no, amount) => {
    // console.log(total);
    const dataOrder = {
      fname: f_name + " " + l_name,
      or_no: or_no,
      amount: amount * 100,
    };
    /*  https://api.skwergroup.com */
    Axios.post("https://skwerstripeapi.skwergroup.com/orderPay", {
      dataOrder,
    }).then((res) => {
      if (res.data.message) {
        /* console.log(res.data.message); */
        setClientSecret(res.data.message);
      }
    });
  };

  // console.log(formData);

  return (
    <div className="container stripeonlinepay">
      <header>
        <a
          className="logo-default dtr-scroll-link"
          href="http://www.skwergroup.com/"
        >
          <img src={SkwerLogo} alt="skwer__logo" className="skwer__logo" />
        </a>
        <span className="app__title">Stripe Online Payment</span>
      </header>
      <main className="grid grid__2">
        <section className="left__box">
          <div className="or_form">
            <label>
              Kindly provide the order number mentioned in the Order Summary
              email you received.
              <br />
              <input
                className="form-control txt_or_no"
                type="text"
                placeholder="OR NO"
                name="or_no"
                value={formData.or_no}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    or_no: event.target.value,
                  })
                }
              />
              <span className="errorMessage">{ORValidation}</span>
            </label>
            <button
              className="btn"
              disabled=""
              onClick={() => {
                getDetails();
              }}
            >
              GET ORDER DETAILS
            </button>
          </div>
        </section>
        <section className={"right__box " + btnDisplaySummary}>
          <div className={"order_form "}>
            <h2>Order Summary</h2>
            {/* ORDER SUMMARY */}
            <div className="grid">
              <div className="order">
                {/* CLIENT DETAILS */}
                <div className="grid border__gray">
                  <div className="order__left">
                    <p>CONTACT PERSON</p>
                    <ul>
                      <li>
                        Name:{" "}
                        {formData.salutation +
                          " " +
                          formData.f_name +
                          " " +
                          formData.l_name}
                      </li>
                      <li>Email: {formData.email}</li>
                      <li>Phone Number: {formData.contact_no}</li>
                    </ul>
                  </div>
                </div>
                {/* PACKAGE DETAILS */}
                <div className="grid border__gray">
                  <div className="order__left">
                    <p>PAYMENT BREAKDOWN</p>
                    <ul>
                      <li>
                        <span>Package - {formData.package}</span>
                        <span className="order_prices">
                          $
                          {package_amt.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </span>
                      </li>
                      <li>
                        <span>Additional Services - {formData.add_serv} </span>
                        <span className="order_prices">
                          $
                          {serv_amt.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </span>
                      </li>
                      <li>
                        <span>Bank Services - {formData.bank_serv}</span>
                        <span className="order_prices">
                          $
                          {bank_amt.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </span>
                      </li>
                      <li>
                        <h3>TOTAL</h3>
                        <h3 className="order_prices">
                          $
                          {total.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </h3>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* STRIPE */}
          <div className={"stripe__btn grid "}>
            {/* <div className="stripe">
              <img src={StripePic} alt="stripe_logo" />
            </div> */}
            {/* <button
              className={"btn " + btnStripe}
              onClick={() => handleCheckout()}
            >
              MAKE PAYMENT via STRIPE
            </button> */}
            <h3>MAKE PAYMENT VIA STRIPE</h3>
            <div className={"stripe__form " + btnStripe}>
              {clientSecret && stripePromise && (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                  <CheckoutForm formData={formData} setFormData={setFormData} />
                </Elements>
              )}
            </div>
          </div>
        </section>
        <span className="version_cont">
          <i>Version: 1.0</i>
        </span>
      </main>
      {/* <footer>FOOTER</footer> */}

      {/* <Footer /> */}
    </div>
  );
}

export default Form;
