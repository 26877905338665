import './App.css';
import React from 'react';
import Form from './components/Form/Form';
import Completion from "./components/Payment/Completion";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ThankYou from './components/Payment/ThankYou';
import SamplePage from './components/Sample/SamplePage';

function App() {
  
  return (
    <>
      <BrowserRouter basename='/'>
        <Routes>
          <Route path="/" element={<Form />} />
          <Route path="/completion" element={<Completion />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="/sample" element={<SamplePage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
