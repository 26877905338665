import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";

import Axios from "axios";

export default function CheckoutForm({ formData, setFormData }) {
  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

  /* END */
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    /*console.log('hi');*/

    if (!stripe || !elements) {
      /* Stripe.js has not yet loaded.
        Make sure to disable form submission until Stripe.js has loaded.*/
      return;
    }

    setIsProcessing(true);

    var orN = formData.or_no;

    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `https://onlinestripepay.skwergroup.com/completion?or=${orN}`,
      },
    });

    if (error) {
      Axios.post("https://skwerstripeapi.skwergroup.com/monitorPayment", {
        or_no: orN,
        remarks:
          error.message + " - " + error.code + " - " + error.decline_code,
        updated_date: date + " " + time,
      }).then(() => {
        console.log("monitoring...");
      });

      alert(error.message);
      console.log(error);
      // Handle the error case here, display an error message, etc.
    } else if (paymentIntent.status === "succeeded") {
      console.log("Payment was successful.");
      // Handle the successful payment case here
      // You can choose to redirect or perform other actions as needed
    }

    setIsProcessing(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button disabled={isProcessing || !stripe || !elements} id="submit">
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
