import React from "react";
import "./ThankYou.css";
import SkwerIcon from "../../assets/images/skwer_logo.svg";
import Axios from "axios";

function ThankYou() {
  const queryParameters = new URLSearchParams(window.location.search);
  const or_no = queryParameters.get("or");

  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

  const class_Name = {
    class: "ThakyouPage",
    id: "IDThankYouPage",
    or: or_no,
  };
  console.log(class_Name);

  /* DATABASE AXIOS */
  const updateMon = () => {
    Axios.post("https://skwerstripeapi.skwergroup.com/monitorPayment", {
      or_no: or_no,
      remarks: "Successful Payment",
      updated_date: date + " " + time,
    }).then(() => {
      console.log("monitoring...");
    });
  };

  updateMon();

  return (
    <div className="thank_div">
      <section>
        <img className="logo" src={SkwerIcon} alt="SkwerGroup" />
        <h1 className="ThankYouPage" id="ThankYouPage">
          Payment Done. Thank you!
        </h1>
        <div className="thank_you_message">
          <h2>Order No: {or_no}</h2>
          <p>
            We are delighted to inform you that your payment has been
            successfully processed. Your order is now confirmed. <br />
            Our dedicated Account Manager will be in touch with you shortly to
            discuss the details of your purchase and ensure a seamless
            experience.
            <br />
            If you have any further questions or require assistance, feel free
            to reach out to our{" "}
            <a href="mailto: info@skwergroup.com">support team</a>. <br />
            <br />
            Thank you for choosing our services. We look forward to serving you!
          </p>
          <a href="https://www.skwergroup.com/" className="btn">
            BACK TO SKWER GROUP HOME PAGE
          </a>
        </div>
      </section>
    </div>
  );
}

export default ThankYou;
