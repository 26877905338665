import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Completion() {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const or_no = queryParameters.get("or");

  /* REDIRECT AFTER SAVING */
  useEffect(() => {
    navigate(`/thankyou?or=${or_no}`, { replace: true });
  });

  /* END */
  return (
    <div className="order__page">
      <h2>Completion Page!</h2>
    </div>
  );
}
